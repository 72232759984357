function toDateObject(date) {
  if (! date) return false;
  if (typeof date === 'string') {
    if (date.includes('T') && ! date.includes(' ') && ! date.includes('Z')) {
      date += 'Z';
    }
  }
  return new Date(date);
}

export function dateString(date) {
  date = toDateObject(date);
  if (! date) return 'never';
  let dayOfMonth = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  return `${year}-${month}-${dayOfMonth}`
}

export function localDateString(date) {
  date = toDateObject(date);
  if (! date) return 'never';
  let dayOfMonth = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  return `${dayOfMonth}-${month}-${year}`
}

export function localDateTimeString(date) {
  date = toDateObject(date);
  if (! date) return 'never';
  let dayOfMonth = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  let hour = ('0' + date.getHours()).slice(-2);
  let minutes = ('0' + date.getMinutes()).slice(-2);
  return `${dayOfMonth}-${month}-${year} ${hour}:${minutes}`
}

export function dateTimeString(date) {
  date = toDateObject(date);
  if (! date) return 'never';
  let dayOfMonth = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hour = date.getHours();
  let minutes = date.getMinutes();
  let diffMs = new Date() - date;
  let diffSec = Math.round(diffMs / 1000);
  let diffMin = diffSec / 60;
  let diffHour = diffMin / 60;
  diffSec = Math.floor(diffSec);
  diffMin = Math.floor(diffMin);
  diffHour = Math.floor(diffHour);

  // formatting
  // year = year.toString().slice(-2);
  month = month < 10 ? '0' + month : month;
  dayOfMonth = dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth;
  hour = hour < 10 ? '0' + hour : hour;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  if (diffSec < 1) {
    return 'Right now';
  } else if (diffMin < 1) {
    return `${diffSec} sec. ago`
  } else if (diffHour < 1) {
    return `${diffMin} min. ago`
  } else if (diffHour < 24) {
    return `${diffHour} hour ago`
  } else {
    return `${dayOfMonth}-${month}-${year} ${hour}:${minutes}`
  }
}

function treatAsUTC(date) {
  let result = toDateObject(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}

export function daysBetween(startDate, endDate) {
  let millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.round((treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay);
}

export function addDays(startDate, duration) {
  return toDateObject(startDate).setDate(toDateObject(startDate).getDate() + duration)
}
