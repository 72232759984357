/*!
=========================================================
* Orderli Dashboard - v0.1.3
=========================================================
*/

import Vue from 'vue';
import App from './App.vue';
import store from '@/store/index';
import BootstrapVue from 'bootstrap-vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import '~/element-ui/lib/theme-chalk/index.css'

// sentry setup
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
if (import.meta.env.VITE_APP_SENTRY_DNS) {
  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_APP_SENTRY_DNS,
    autoSessionTracking: true,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracingOptions: {
      trackComponents: true,
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

Vue.use(BootstrapVue);

// router setup
import router from './routes/starterRouter';

// plugin setup
Vue.use(DashboardPlugin);

import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput);
import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter);

import VueMask from 'v-mask'
Vue.use(VueMask);

// NProgress
import "@/assets/css/nprogress.css";

//Axios setup
import Axios from 'axios'
Axios.defaults.baseURL = import.meta.env.VITE_APP_SERVER_URL;

const token = localStorage.getItem('jwt')
if (token) {
  Axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
}

const UNAUTHORIZED = 401;

Axios.interceptors.response.use(
  response => response,
  error => {
    //Log the error to Sentry
    Sentry.captureException(error)

    const {status} = error.response || {};
    if (status === UNAUTHORIZED) {
      store.dispatch('userShouldHaveBeenLoggedOut');
    }
    return Promise.reject(error);
  }
);

if(document.querySelector('meta[name="csrf-token"]')){
  Axios.defaults.headers.post['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}
/* eslint-disable no-new */

window.Vue = Vue;
window.router = router;
new Vue({
  el: '#app',
  store,
  render: h => h(App),
  router,
  created() {
    processQueryParams(new URL(location.href).searchParams);
    store.dispatch('checkOnboardingStatus')
  }
});

function processQueryParams(URLSearchParams){
  if(URLSearchParams.has('accountId') && URLSearchParams.has('restaurantId')){
    store.commit('setAccount', parseInt(URLSearchParams.get('accountId')))
    store.commit('setRestaurant', parseInt(URLSearchParams.get('restaurantId')))
    URLSearchParams.delete('accountId');
    URLSearchParams.delete('restaurantId');

    //Make a new object for the params, each 'entry' is a [key, value] tupple
    const result = {}
    for(const [key, value] of URLSearchParams.entries()) {
      result[key] = value;
    }

    router.replace({path: window.location.pathname, query: result, hash: window.location.hash}).catch(() => {});
  }
}

Axios.interceptors.request.use(
  req => {
    // console.log(store.getters.userEmail);
    if(req.headers && req.headers.common.Authorization && req.method !== 'get' && store.getters.userEmail === "ae@ycombinator.com" && req.url.substring(0,5) !== "users" && req.url.substring(0,10) !== "onboarding"){
      //Auth was required and not GET request
      alert('Sorry YC, we hacked together a quick read-only access of our dashboard in 10 minutes, so this action was blocked to prevent mutating our customers data.')

    }else if(store.getters.userEmail === "ae@ycombinator.com" && req.url.includes("/orders/")  && !req.url.includes("/analytics/")){
      alert('Sorry YC, we disabled loading the order data, since this contains personal details of our customers\' guests and we cannot share that data due to European privacy laws.')
    }
    else{
      return req;
    }

  }
);

String.prototype.toTitleCase = function() {
  return this.split(' ').map(word =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
};

