import Vuex from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import router from '../routes/starterRouter';
import swal from "sweetalert2";
import * as menu from './modules/menu.js';
import * as untill from './modules/pos/untill.js';
import * as Sentry from "@sentry/browser";
import {diff} from 'deep-object-diff';
import download from "downloadjs";
import localForage from 'localforage';
import {afterRead} from "@popperjs/core";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    menu,
    untill,
  },
  state: {
    accounts: [],
    account: {
      coupons: [],
      invoices: [],
      invoicesCallComplete: null,
    },
    restaurant: {
      coupons: [],
      api_tokens: [],
    },
    billing: {
      loading: false,
      loaded: false
    },
    restaurant_billing: {},
    admin: {
      unlinkedRestaurants: [],
      userIdentityReports: [],
      users: [],
      adminUsers: [],
      subscriptions: [],
      methodPriceGroups: [],
      discounts: [{id: null, name: 'None', duration: 'forever'}],
      resellers: [],
      restaurantRevenues: null,
      prevRestaurantRevenues: null,
      restaurantPayments: null,
      prevRestaurantPayments: null,
      psps: [],
      endingTrials: [],
    },
    devices: {
      allTablets: [],
      latestAppVersion: null,
    },
    payment_methods: [{value: 'manual', label: 'Manual', color: '#6180b7'},
      {value: 'ideal', label: 'iDEAL', color: '#db87b9'},
      {value: 'card', label: 'Creditcards', color: '#d25d5d'},
      {value: 'paypal', label: 'PayPal', color: '#71abdd'},
      {value: 'giropay', label: 'Giropay', color: '#7c8bc6'},
      {value: 'payconiq', label: 'Payconiq', color: '#ea91c1'},
      {value: 'applepay', label: 'Apple Pay', color: '#929292'},
      {value: 'bancontact', label: 'Bancontact', color: '#ffed81'},
      {value: 'cash', label: 'Cash', color: '#b6d884'},
      {value: 'BTC', label: 'Bitcoin', color: '#fbb442'},
      {value: 'BCH', label: 'Bitcoin Cash', color: '#8fcd9e'},
      {value: 'DOGE', label: 'Dogecoin', color: '#bfb86c'},
      {value: 'XRP', label: 'Ripple', color: '#6acbde'}],
    areaColors: [{value: null, label: 'All areas', color: null},
      {value: 'red', label: 'Red', color: '#c02424'},
      {value: 'blue', label: 'Blue', color: '#2450c0'},
      {value: 'brown', label: 'Brown', color: '#8c5312'},
      {value: 'yellow', label: 'Yellow', color: '#c0bd24'},
      {value: 'purple', label: 'Purple', color: '#9424c0'},
      {value: 'orange', label: 'Orange', color: '#c04e24'},
      {value: 'green', label: 'Green', color: '#34c024'}],
    subscriptions: [],
    credits: null,
    qrs: [],
    apiURL: import.meta.env.VITE_APP_SERVER_URL + 'dashboard/api/v1',
    logoURL: import.meta.env.VITE_APP_SERVER_URL + 'logo/',
    imageURL: import.meta.env.VITE_APP_SERVER_URL + 'images/',
    tempImageURL: import.meta.env.VITE_APP_SERVER_URL + 'temp/images/',
    analytics: {
      timeframeMap: new Map([
        ['Y', 'year'],
        ['Q', 'quarter'],
        ['M', 'month'],
        ['W', 'week'],
        ['D', 'day'],
        ['H', 'hour'],
      ])
    },
    auth: {
      token: localStorage.getItem('jwt') || '',
      status: '',
      adminImpersonateRole: null,
      user: {
        completed: false,
        email: null,
        first_name: null,
        is_admin: undefined,
        is_moderator: undefined,
        is_reseller: undefined,
        last_name: null,
        phone_number: null,
      },
      loginDetails: {},
      loggedIn: undefined,
      accountId: parseInt(localStorage.getItem('accountId')) || undefined,
      restaurantId: parseInt(localStorage.getItem('restaurantId')) || undefined,
      restaurantCurrency: localStorage.getItem('restaurantCurrency') || undefined,
      onboarding: {
        user_completed: undefined,
        account_completed: undefined,
        subscriptionStatus: undefined,
        mandateUrl: undefined,
        subscription: undefined,
        billingStatus: undefined,
        accountBilling: {
          payment_methods: [],
          setup_intent_status: undefined,
        }
      },
    },
    subscriptionFeatures: [
      {value: "multiple_locations", label: "Multiple locations"},
      {value: "unlimited_tablets", label: "Unlimited Tablets"},
      {value: "inventory_management", label: "Inventory Management"},
      {value: "pos_integration", label: "Pos Integration"},
      {value: "receipt_printer", label: "Receipt Printer"},
      {value: "up_selling", label: "Up-Selling"},
      {value: "cross_selling", label: "Cross-Selling"},
      {value: "payment_required", label: "Payment Required"},
      {value: "custom_colors", label: "Custom Colors"},
      {value: "thuis", label: "Thuis"},
      {value: "standard_support", label: "Standard Support"},
      {value: "priority_support", label: "Priority Support"},
      {value: "advanced_branding", label: "Advanced Branding"},
      {value: "custom_domain", label: "Custom domain"},
      {value: "auto_import", label: "Auto import"},
      {value: "lightspeed_receipt_linking", label: "Lightspeed L receipt stacking"}
    ],
    languages: [
      {
        iso639: "af",
        iso3166: "ZA",
        name: "Afrikaans",
        native: "Afrikaans",
        emoji: "🇿🇦",
      },
      {
        iso639: "ak",
        iso3166: "GH",
        name: "Akan",
        native: "Akana",
        emoji: "🇬🇭",
      },
      {
        iso639: "sq",
        iso3166: "AL",
        name: "Albanian",
        native: "Shqip",
        emoji: "🇦🇱",
      },
      {
        iso639: "ar",
        iso3166: "AE",
        name: "Arabic",
        native: "العربية",
        emoji: "🇦🇪",
      },
      {
        iso639: "as",
        iso3166: "IN",
        name: "Assamese",
        native: "অসমীয়া",
        emoji: "🇮🇳",
      },
      {
        iso639: "az",
        iso3166: "AZ",
        name: "Azerbaijani",
        native: "Azərbaycanca / آذربايجان",
        emoji: "🇦🇿",
      },
      {
        iso639: "bm",
        iso3166: "ML",
        name: "Bambara",
        native: "Bamanankan",
        emoji: "🇲🇱",
      },
      {
        iso639: "eu",
        iso3166: "ES",
        name: "Basque",
        native: "Euskara",
        emoji: "🇪🇸",
      },
      {
        iso639: "be",
        iso3166: "BY",
        name: "Belarusian",
        native: "Беларуская",
        emoji: "🇧🇾",
      },
      {
        iso639: "bn",
        iso3166: "BD",
        name: "Bengali",
        native: "বাংলা",
        emoji: "🇧🇩",
      },
      {
        iso639: "bs",
        iso3166: "BA",
        name: "Bosnian",
        native: "Bosanski",
        emoji: "🇧🇦",
      },
      {
        iso639: "br",
        iso3166: "FR",
        name: "Breton",
        native: "Brezhoneg",
        emoji: "🇫🇷",
      },
      {
        iso639: "bg",
        iso3166: "BG",
        name: "Bulgarian",
        native: "Български",
        emoji: "🇧🇬",
      },
      {
        iso639: "my",
        iso3166: "MM",
        name: "Burmese",
        native: "မြန်မာစာ",
        emoji: "🇲🇲",
      },
      {
        iso639: "km",
        iso3166: "KH",
        name: "Cambodian",
        native: "ភាសាខ្មែរ",
        emoji: "🇰🇭",
      },
      {
        iso639: "ca",
        iso3166: "ES",
        name: "Catalan",
        native: "Català",
        emoji: "🇪🇸",
      },
      {
        iso639: "zh",
        iso3166: "CN",
        name: "Chinese",
        native: "中文",
        emoji: "🇨🇳",
      },
      {
        iso639: "kw",
        iso3166: "GB",
        name: "Cornish",
        native: "Kernewek",
        emoji: "🇬🇧",
      },
      {
        iso639: "hr",
        iso3166: "HR",
        name: "Croatian",
        native: "Hrvatski",
        emoji: "🇭🇷",
      },
      {
        iso639: "cs",
        iso3166: "CZ",
        name: "Czech",
        native: "Čeština",
        emoji: "🇨🇿",
      },
      {
        iso639: "da",
        iso3166: "DK",
        name: "Danish",
        native: "Dansk",
        emoji: "🇩🇰",
      },
      {
        iso639: "nl",
        iso3166: "NL",
        name: "Dutch",
        native: "Nederlands",
        emoji: "🇳🇱",
      },
      {
        iso639: "en",
        iso3166: "GB",
        name: "English",
        native: "English",
        emoji: "🇬🇧",
      },
      {
        iso639: "eo",
        iso3166: null,
        name: "Esperanto",
        native: "Esperanto",
        emoji: "🏳️",
      },
      {
        iso639: "et",
        iso3166: "EE",
        name: "Estonian",
        native: "Eesti",
        emoji: "🇪🇪",
      },
      {
        iso639: "ee",
        iso3166: "GH",
        name: "Ewe",
        native: "Ɛʋɛ",
        emoji: "🇬🇭",
      },
      {
        iso639: "fo",
        iso3166: "FO",
        name: "Faroese",
        native: "Føroyskt",
        emoji: "🇫🇴",
      },
      {
        iso639: "hy",
        iso3166: "AM",
        name: "Fijian",
        native: "Na Vosa Vakaviti",
        emoji: "🇦🇲",
      },
      {
        iso639: "fi",
        iso3166: "FI",
        name: "Finnish",
        native: "Suomi",
        emoji: "🇫🇮",
      },
      {
        iso639: "fr",
        iso3166: "FR",
        name: "French",
        native: "Français",
        emoji: "🇫🇷",
      },
      {
        iso639: "gl",
        iso3166: "ES",
        name: "Galician",
        native: "Galego",
        emoji: "🇪🇸",
      },
      {
        iso639: "lg",
        iso3166: "UG",
        name: "Ganda",
        native: "Luganda",
        emoji: "🇺🇬",
      },
      {
        iso639: "ka",
        iso3166: "GE",
        name: "Georgian",
        native: "ქართული",
        emoji: "🇬🇪",
      },
      {
        iso639: "de",
        iso3166: "DE",
        name: "German",
        native: "Deutsch",
        emoji: "🇩🇪",
      },
      {
        iso639: "el",
        iso3166: "GR",
        name: "Greek",
        native: "Ελληνικά",
        emoji: "🇬🇷",
      },
      {
        iso639: "kl",
        iso3166: "GL",
        name: "Greenlandic",
        native: "Kalaallisut",
        emoji: "🇬🇱",
      },
      {
        iso639: "gu",
        iso3166: "IN",
        name: "Gujarati",
        native: "ગુજરાતી",
        emoji: "🇮🇳",
      },
      {
        iso639: "ha",
        iso3166: "NG",
        name: "Hausa",
        native: "هَوُسَ",
        emoji: "🇳🇬",
      },
      {
        iso639: "he",
        iso3166: "IL",
        name: "Hebrew",
        native: "עברית",
        emoji: "🇮🇱",
      },
      {
        iso639: "hi",
        iso3166: "IN",
        name: "Hindi",
        native: "हिन्दी",
        emoji: "🇮🇳",
      },
      {
        iso639: "hu",
        iso3166: "HU",
        name: "Hungarian",
        native: "Magyar",
        emoji: "🇭🇺",
      },
      {
        iso639: "is",
        iso3166: "IS",
        name: "Icelandic",
        native: "Íslenska",
        emoji: "🇮🇸",
      },
      {
        iso639: "ig",
        iso3166: "NG",
        name: "Igbo",
        native: "Igbo",
        emoji: "🇳🇬",
      },
      {
        iso639: "id",
        iso3166: "ID",
        name: "Indonesian",
        native: "Bahasa Indonesia",
        emoji: "🇮🇩",
      },
      {
        iso639: "ga",
        iso3166: "IE",
        name: "Irish",
        native: "Gaeilge",
        emoji: "🇮🇪",
      },
      {
        iso639: "it",
        iso3166: "IT",
        name: "Italian",
        native: "Italiano",
        emoji: "🇮🇹",
      },
      {
        iso639: "ja",
        iso3166: "JP",
        name: "Japanese",
        native: "日本語",
        emoji: "🇯🇵",
      },
      {
        iso639: "kn",
        iso3166: "IN",
        name: "Kannada",
        native: "ಕನ್ನಡ",
        emoji: "🇮🇳",
      },
      {
        iso639: "kk",
        iso3166: "KZ",
        name: "Kazakh",
        native: "Қазақша",
        emoji: "🇰🇿",
      },
      {
        iso639: "ki",
        iso3166: "KE",
        name: "Kikuyu",
        native: "Gĩkũyũ",
        emoji: "🇰🇪",
      },
      {
        iso639: "rn",
        iso3166: "BI",
        name: "Kirundi",
        native: "Kirundi",
        emoji: "🇧🇮",
      },
      {
        iso639: "ko",
        iso3166: "KR",
        name: "Korean",
        native: "한국어",
        emoji: "🇰🇷",
      },
      {
        iso639: "ku",
        iso3166: null,
        name: "Kurdish",
        native: "Kurdî / كوردی",
        emoji: "🏳️",
      },
      {
        iso639: "lv",
        iso3166: "LV",
        name: "Latvian",
        native: "Latviešu",
        emoji: "🇱🇻",
      },
      {
        iso639: "ln",
        iso3166: "CG",
        name: "Lingala",
        native: "Lingála",
        emoji: "🇨🇬",
      },
      {
        iso639: "lt",
        iso3166: "LT",
        name: "Lithuanian",
        native: "Lietuvių",
        emoji: "🇱🇹",
      },
      {
        iso639: "lu",
        iso3166: "CD",
        name: "Luba-Katanga",
        native: "Tshiluba",
        emoji: "🇨🇩",
      },
      {
        iso639: "mk",
        iso3166: "MK",
        name: "Macedonian",
        native: "Македонски",
        emoji: "🇲🇰",
      },
      {
        iso639: "mg",
        iso3166: "MG",
        name: "Malagasy",
        native: "Malagasy",
        emoji: "🇲🇬",
      },
      {
        iso639: "ms",
        iso3166: "MY",
        name: "Malay",
        native: "Bahasa Melayu",
        emoji: "🇲🇾",
      },
      {
        iso639: "ml",
        iso3166: "IN",
        name: "Malayalam",
        native: "മലയാളം",
        emoji: "🇮🇳",
      },
      {
        iso639: "mt",
        iso3166: "MT",
        name: "Maltese",
        native: "bil-Malti",
        emoji: "🇲🇹",
      },
      {
        iso639: "gv",
        iso3166: "GB",
        name: "Manx",
        native: "Gaelg",
        emoji: "🇬🇧",
      },
      {
        iso639: "mr",
        iso3166: "IN",
        name: "Marathi",
        native: "मराठी",
        emoji: "🇮🇳",
      },
      {
        iso639: "mn",
        iso3166: "MN",
        name: "Mongolian",
        native: "Монгол",
        emoji: "🇲🇳",
      },
      {
        iso639: "ne",
        iso3166: "NP",
        name: "Nepali",
        native: "नेपाली",
        emoji: "🇳🇵",
      },
      {
        iso639: "nd",
        iso3166: "ZW",
        name: "North Ndebele",
        native: "Sindebele",
        emoji: "🇿🇼",
      },
      {
        iso639: "no",
        iso3166: "NO",
        name: "Norwegian",
        native: "Norsk",
        emoji: "🇳🇴",
      },
      {
        iso639: "or",
        iso3166: "IN",
        name: "Oriya",
        native: "ଓଡ଼ିଆ",
        emoji: "🇮🇳",
      },
      {
        iso639: "om",
        iso3166: "ET",
        name: "Oromo",
        native: "Oromoo",
        emoji: "🇪🇹",
      },
      {
        iso639: "pa",
        iso3166: "PK",
        name: "Panjabi / Punjabi",
        native: "ਪੰਜਾਬੀ / पंजाबी / پنجابي",
        emoji: "🇵🇰",
      },
      {
        iso639: "ps",
        iso3166: "AF",
        name: "Pashto",
        native: "پښتو",
        emoji: "🇦🇫",
      },
      {
        iso639: "fa",
        iso3166: "IR",
        name: "Persian",
        native: "فارسی",
        emoji: "🇮🇷",
      },
      {
        iso639: "ff",
        iso3166: "CN",
        name: "Peul",
        native: "Fulfulde",
        emoji: "🇨🇳",
      },
      {
        iso639: "pl",
        iso3166: "PL",
        name: "Polish",
        native: "Polski",
        emoji: "🇵🇱",
      },
      {
        iso639: "pt",
        iso3166: "PT",
        name: "Portuguese",
        native: "Português",
        emoji: "🇵🇹",
      },
      {
        iso639: "rm",
        iso3166: "CH",
        name: "Raeto Romance",
        native: "Rumantsch",
        emoji: "🇨🇭",
      },
      {
        iso639: "ro",
        iso3166: "RO",
        name: "Romanian",
        native: "Română",
        emoji: "🇷🇴",
      },
      {
        iso639: "ru",
        iso3166: "RU",
        name: "Russian",
        native: "Русский",
        emoji: "🇷🇺",
      },
      {
        iso639: "rw",
        iso3166: "RW",
        name: "Rwandi",
        native: "Kinyarwandi",
        emoji: "🇷🇼",
      },
      {
        iso639: "sg",
        iso3166: "CF",
        name: "Sango",
        native: "Sängö",
        emoji: "🇨🇫",
      },
      {
        iso639: "sr",
        iso3166: "RS",
        name: "Serbian",
        native: "Српски",
        emoji: "🇷🇸",
      },
      {
        iso639: "sn",
        iso3166: "ZW",
        name: "Shona",
        native: "chiShona",
        emoji: "🇿🇼",
      },
      {
        iso639: "ii",
        iso3166: "CN",
        name: "Sichuan Yi",
        native: "ꆇꉙ / 四川彝语",
        emoji: "🇨🇳",
      },
      {
        iso639: "si",
        iso3166: "LK",
        name: "Sinhalese",
        native: "සිංහල",
        emoji: "🇱🇰",
      },
      {
        iso639: "sk",
        iso3166: "SK",
        name: "Slovak",
        native: "Slovenčina",
        emoji: "🇸🇰",
      },
      {
        iso639: "sl",
        iso3166: "SI",
        name: "Slovenian",
        native: "Slovenščina",
        emoji: "🇸🇮",
      },
      {
        iso639: "so",
        iso3166: "SO",
        name: "Somalia",
        native: "Soomaaliga",
        emoji: "🇸🇴",
      },
      {
        iso639: "es",
        iso3166: "ES",
        name: "Spanish",
        native: "Español",
        emoji: "🇪🇸",
      },
      {
        iso639: "sw",
        iso3166: "TZ",
        name: "Swahili",
        native: "Kiswahili",
        emoji: "🇹🇿",
      },
      {
        iso639: "sv",
        iso3166: "SE",
        name: "Swedish",
        native: "Svenska",
        emoji: "🇸🇪",
      },
      {
        iso639: "ta",
        iso3166: "IN",
        name: "Tamil",
        native: "தமிழ்",
        emoji: "🇮🇳",
      },
      {
        iso639: "te",
        iso3166: "IN",
        name: "Telugu",
        native: "తెలుగు",
        emoji: "🇮🇳",
      },
      {
        iso639: "th",
        iso3166: "TH",
        name: "Thai",
        native: "ไทย / Phasa Thai",
        emoji: "🇹🇭",
      },
      {
        iso639: "bo",
        iso3166: "CN",
        name: "Tibetan",
        native: "བོད་ཡིག / Bod skad",
        emoji: "🇨🇳",
      },
      {
        iso639: "ti",
        iso3166: "ER",
        name: "Tigrinya",
        native: "ትግርኛ",
        emoji: "🇪🇷",
      },
      {
        iso639: "to",
        iso3166: "TO",
        name: "Tonga",
        native: "Lea Faka-Tonga",
        emoji: "🇹🇴",
      },
      {
        iso639: "tr",
        iso3166: "TR",
        name: "Turkish",
        native: "Türkçe",
        emoji: "🇹🇷",
      },
      {
        iso639: "uk",
        iso3166: "UA",
        name: "Ukrainian",
        native: "Українська",
        emoji: "🇺🇦",
      },
      {
        iso639: "ur",
        iso3166: "PK",
        name: "Urdu",
        native: "اردو",
        emoji: "🇵🇰",
      },
      {
        iso639: "uz",
        iso3166: "UZ",
        name: "Uzbek",
        native: "Ўзбек",
        emoji: "🇺🇿",
      },
      {
        iso639: "vi",
        iso3166: "VN",
        name: "Vietnamese",
        native: "Tiếng Việt",
        emoji: "🇻🇳",
      },
      {
        iso639: "cy",
        iso3166: "GB",
        name: "Welsh",
        native: "Cymraeg",
        emoji: "🇬🇧",
      },
      {
        iso639: "fy",
        iso3166: "NL",
        name: "West Frisian",
        native: "Frysk",
        emoji: "🇳🇱",
      },
      {
        iso639: "yo",
        iso3166: "NG",
        name: "Yoruba",
        native: "Yorùbá",
        emoji: "🇳🇬",
      },
      {
        iso639: "zu",
        iso3166: "ZA",
        name: "Zulu",
        native: "isiZulu",
        emoji: "🇿🇦",
      },
    ],
    bonusMonths: [{
        label: '2023',
        options: [
          {"month_id": 13, "label": "Januari 2023"},
          {"month_id": 14, "label": "Februari 2023"},
          {"month_id": 15, "label": "Maart 2023"},
          {"month_id": 16, "label": "April 2023"},
          {"month_id": 17, "label": "Mei 2023"},
          {"month_id": 18, "label": "Juni 2023"},
          {"month_id": 19, "label": "Juli 2023"},
          {"month_id": 20, "label": "Augustus 2023"},
          {"month_id": 21, "label": "September 2023"},
          {"month_id": 22, "label": "Oktober 2023"},
          {"month_id": 23, "label": "November 2023"},
          {"month_id": 24, "label": "December 2023"},
        ],
      },
      {
        label: '2024',
        options: [
          {"month_id": 25, "label": "Januari 2024"},
          {"month_id": 26, "label": "Februari 2024"},
          {"month_id": 27, "label": "Maart 2024"},
          {"month_id": 28, "label": "April 2024"},
          {"month_id": 29, "label": "Mei 2024"},
          {"month_id": 30, "label": "Juni 2024"},
          {"month_id": 31, "label": "Juli 2024"},
          {"month_id": 32, "label": "Augustus 2024"},
          {"month_id": 33, "label": "September 2024"},
          {"month_id": 34, "label": "Oktober 2024"},
          {"month_id": 35, "label": "November 2024"},
          {"month_id": 36, "label": "December 2024"},
        ],
      },
      {
        label: '2025',
        options: [
          {"month_id": 37, "label": "Januari 2025"},
          {"month_id": 38, "label": "Februari 2025"},
          {"month_id": 39, "label": "Maart 2025"},
          {"month_id": 40, "label": "April 2025"},
          {"month_id": 41, "label": "Mei 2025"},
          {"month_id": 42, "label": "Juni 2025"},
          {"month_id": 43, "label": "Juli 2025"},
          {"month_id": 44, "label": "Augustus 2025"},
          {"month_id": 45, "label": "September 2025"},
          {"month_id": 46, "label": "Oktober 2025"},
          {"month_id": 47, "label": "November 2025"},
          {"month_id": 48, "label": "December 2025"}
        ],
      },
      {
        label: '2022',
        options: [
          {"month_id": 1, "label": "Januari 2022"},
          {"month_id": 2, "label": "Februari 2022"},
          {"month_id": 3, "label": "Maart 2022"},
          {"month_id": 4, "label": "April 2022"},
          {"month_id": 5, "label": "Mei 2022"},
          {"month_id": 6, "label": "Juni 2022"},
          {"month_id": 7, "label": "Juli 2022"},
          {"month_id": 8, "label": "Augustus 2022"},
          {"month_id": 9, "label": "September 2022"},
          {"month_id": 10, "label": "Oktober 2022"},
          {"month_id": 11, "label": "November 2022"},
          {"month_id": 12, "label": "December 2022"},
        ],
      },
    ],
    bonusStatuses: [
      {
        value: 'claimed',
        label: '💼 Claimed',
      },
      {
        value: 'waiting_for_payment',
        label: '⏳ Waiting for payment',
      },
      {
        value: 'needs_clarification',
        label: '🕵️ TBD / needs clarification',
      },
      {
        value: 'old_bonus_structure',
        label: '👴🏼 Old bonus structure',
      },
      {
        value: 'rejected',
        label: '❌ Rejected',
      },
      {
        value: 'granted',
        label: '🎉 Granted',
      },
    ],
  },
  getters: {
    isLoggedIn(state) {
      return state.auth.loggedIn;
    },
    isAdmin(state) {
      if(state.auth.adminImpersonateRole) return false;
      return state.auth.user.is_admin;
    },
    isImpersonating(state) {
      return state.auth.adminImpersonateRole != null;
    },
    canManageAccounts(state) {
      if(state.auth.adminImpersonateRole) return false;
      return state.auth.user.is_admin || state.auth.user.is_moderator || state.auth.user.is_reseller;
    },
    canManageFinancial(state) {
      let people = ["dorus@orderli.com", "sven@orderli.com", "maurits@orderli.com", "marieke@orderli.com", "amy-jane@orderli.com"]
      return people.includes(state.auth.user.email)
    },
    currentRole(state, getters) {
      return state.auth.adminImpersonateRole ? state.auth.adminImpersonateRole : getters.currentAccount?.role;
    },
    currentUser(state) {
      return state.auth.user;
    },
    userFirstName(state) {
      return state.auth.user.first_name;
    },
    userLastName(state) {
      return state.auth.user.last_name;
    },
    userFullName(state) {
      return state.auth.user.first_name + " " + state.auth.user.last_name;
    },
    userEmail(state) {
      return state.auth.user.email
    },
    onboardingCompleted(state, getters) {

      //Make sure that admins, moderators and resellers only complete their own user onboarding
      //Check if user_completed is false, because if there are no accounts, onboarding status cannot be checked, so also continue if undefined
      if (getters.canManageAccounts && state.auth.onboarding.user_completed !== false) return true;

      if (state.auth.onboarding.user_completed === false || state.auth.onboarding.account_completed === false) {
        // return state.auth.onboarding.user_completed && state.auth.onboarding.account_completed && (state.auth.onboarding.subscriptionStatus === 'active' || state.auth.onboarding.subscription === 'manual') && getters.currentAccount?.restaurants.length > 0;        return state.auth.onboarding.user_completed && state.auth.onboarding.account_completed && (state.auth.onboarding.subscriptionStatus === 'active' || state.auth.onboarding.subscription === 'manual') && getters.currentAccount?.restaurants.length > 0;
        return state.auth.onboarding.user_completed && state.auth.onboarding.account_completed && getters.currentAccount?.restaurants.length > 0;
      }
      // else if(state.auth.onboarding.user_completed === undefined || state.auth.onboarding.account_completed === undefined || state.auth.onboarding.subscriptionStatus === undefined){
      else if (state.auth.onboarding.user_completed === undefined || state.auth.onboarding.account_completed === undefined) {
        return undefined;
      } else {
        // return state.auth.onboarding.user_completed && state.auth.onboarding.account_completed && (state.auth.onboarding.subscriptionStatus === 'active' || state.auth.onboarding.subscription === 'manual') && getters.currentAccount?.restaurants.length > 0;
        return state.auth.onboarding.user_completed && state.auth.onboarding.account_completed && getters.currentAccount?.restaurants.length > 0;
      }
    },
    currentAccountId(state) {
      return state.auth.accountId;
    },
    currentAccount(state, getters) {
      return state.accounts.find(acc => acc.id === getters.currentAccountId);
    },
    accountInvoices(state, getters) {
      return state.account.invoices;
    },
    allAccounts(state) {
      return state.accounts;
    },
    allRestaurants(state) {
      let newArray = [];

      state.accounts.forEach(function (account) {
        account.restaurants.forEach(function (restaurant) {

          newArray.push({
            ...restaurant,
            'accountName': account.name,
            'accountId': account.id,
            'accountSubscription': account.subscription,
            'new_billing': account.new_billing
          });
        })
      })
      return newArray;
    },
    allRestaurantsMap(state, getters) {
      return new Map(getters.allRestaurants.map(element => [element.id, element]));
    },
    currentRestaurantId(state) {
      return state.auth.restaurantId;
    },
    currentRestaurantCurrency(state) {
      return state.auth.restaurantCurrency;
    },
    currentRestaurant(state, getters) {
      return getters.currentAccount?.restaurants.find(y => y.id === getters.currentRestaurantId);
    },
    billingCompleted(state) {
      return true

      //TODO fix below later
      // let status = state.auth.onboarding.subscriptionStatus;
      // let type = state.auth.onboarding.subscription;
      // if (status === 'active' || type === 'manual'){
      //   return true
      // }else if( status === 'unverified'){
      //   return false
      // }else{
      //   return status
      // }
    },
    billing(state) {
      return state.billing;
    },
    firstSubscription(state) {
      let billing = state.billing;
      let subscription = null;
      if ('restaurants' in billing && Object.keys(billing['restaurants']).length > 0) {
        Object.keys(billing['restaurants']).forEach(restaurantId => {
          let restaurant = billing['restaurants'][restaurantId];
          if (! subscription && restaurant && restaurant.subscription) {
            subscription = restaurant.subscription;
          }
        });
      }
      return subscription;
    },
    mandateGiven(state) {
      return state.auth.onboarding.billingStatus;
    },
    allTabletsOfCurrentRestaurant(state, getters) {
      return state.devices.allTablets.filter(x => x.restaurant_id === getters.currentRestaurantId);
    },
    subscriptions(state) {
      return state.subscriptions;
    },
    allQRsOfCurrentRestaurant(state) {
      return state.qrs;
    }
    // currentRestaurantLanguages(getters){
    //   return this.currentRestaurant.languages;
    // },
  },
  actions: {
    login({commit, dispatch}, loginData) {
      return new Promise((resolve, reject) => {
        commit('auth_request')

        // resolve(); return;

        axios.post('dashboard/api/v1/users/login', loginData)
          .then(function (response) {
            commit('auth_success', loginData)
            commit('setJwt', response.data.token)
            commit('setUserLoggedInStatus', true);
            dispatch('checkOnboardingStatus');
            resolve(response)
            //Routing is handled locally by the component that called the login action

          })
          .catch(function (error) {
            commit('auth_error')
            console.log(error);
            reject(error)
          })
      })
    },
    register({commit}, registerData) {
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/users/signup', registerData)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    confirmEmail({commit, dispatch}, token) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/users/confirm/${token}`)
          .then(function (response) {
            commit('setJwt', response.data.token)
            commit('setUserLoggedInStatus', true);
            dispatch('checkOnboardingStatus');
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    validateInvite({commit}, token) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/users/validate-invite/${token}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    join({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/users/join/${data.token}`, data.passwordData)
          .then(function (response) {
            commit('setJwt', response.data.token)
            commit('setUserLoggedInStatus', true);
            dispatch('checkOnboardingStatus');
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    forgotPassword({commit}, email) {
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/users/forget', email)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    validateReset({commit}, token) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/users/validate-reset/${token}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    resetPassword({commit, dispatch}, resetData) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/users/reset/${resetData.token}`, resetData.data)
          .then(function (response) {
            commit('setJwt', response.data.token)
            commit('setUserLoggedInStatus', true);
            dispatch('checkOnboardingStatus');
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    logout({commit}) {
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/users/logout')
          .then(function (response) {
            commit('setUserLoggedInStatus', false);
            commit('removeJwt');
            router.push('/login');
            resolve(response)

          })
          .catch(function (error) {
            if (error.response.status === 401) {
              console.log('user was already logged out');
              commit('setUserLoggedInStatus', false);
              commit('removeJwt');
              router.push('/login');
            } else {
              reject(error)
            }
          })
      })
    },
    userShouldHaveBeenLoggedOut({state, commit}) {
      if (state.auth.loggedIn && !import.meta.env.VITE_APP_SKIP_ROUTING) {
        //TODO it might be possible that tstate.auth.loggedIn is false if someone was logged in but the token expired, prompting them to get loads of 401 errors on every action
        //Only do these actions if the user was still marked as logged in
        //TODO trigger a notification to explain the user why it was logged out and redirected
        commit('setUserLoggedInStatus', false);
        commit('removeJwt');
        router.push('/login');
      }

    },
    checkOnboardingStatus({commit, getters, dispatch, state}) {
      //TODO if any error returns, commit setOnboardingStatus to false
      dispatch('checkUserStatus')
        .then(function (userResponse) {
          //User was succesfully authenticated and logged in, continue checking onboarding

          //Check if user isAdmin, if so then it should provide true value for retrieving cached call of all accounts
          let isAdmin = userResponse.data.user.is_admin;
          dispatch('getAccounts', isAdmin)
            .then(function (res) {
              //accounts received

              if (!res.data.accounts.length) {
                //zero accounts were returned so the length of the array is 0
                if (getters.canManageAccounts) {
                  //User is admin, moderator, or reseller

                  router.push('/reseller');
                  return;
                } else {

                }
              }

              //Check if previously an account/restaurant has been selected and is saved in localstorage
              if (!(state.auth.accountId && res.data.accounts.some(x => x.id === state.auth.accountId))) {
                //No previously used account was found in localstorage or the account in localstorage doesn't exist in the response. Select the first account and restaurant
                console.log('No previously used account was found in localstorage. Select the first account and restaurant');
                commit('setAccount', res.data.accounts[0].id)
                commit('setRestaurant', res.data.accounts[0].restaurants[0]?.id)
              } else if (!(state.auth.restaurantId && res.data.accounts.filter(x => x.id === state.auth.accountId)[0].restaurants.some(x => x.id === state.auth.restaurantId))) {
                //A previously used account was found in localstorage, but no restaurant was selected or the restaurant id in localstorage doesn't exist within this account. Select the first restaurant automatically
                console.log('A previously used account was found in localstorage, but no restaurant was selected. Select the first restaurant automatically');
                commit('setRestaurant', res.data.accounts.find(x => x.id === state.auth.accountId).restaurants[0]?.id)
              }

              dispatch('getOnboardingStatus')
                .then(function (res) {
                  //Got onboarding status, check if completed
                  commit('setSubscription', res.data.subscription_selected);
                  if (!res.data.user_completed) {
                    //User account not completed,
                    commit('setOnboardingUserCompletedStatus', false);
                    commit('setOnboardingStatus', false);
                  } else {
                    //User account completed
                    commit('setOnboardingUserCompletedStatus', true);
                  }

                  if (!res.data.account_completed) {
                    //Account data not completed,
                    commit('setOnboardingAccountCompletedStatus', false);
                    commit('setOnboardingStatus', false);
                    // commit('setSubscriptionStatus', 'unverified'); TODO do differently
                  } else {
                    //Account completed
                    commit('setOnboardingAccountCompletedStatus', true);
                  }

                  if (!res.data.account_completed) return; //Return only if account was not completed yet, because you can't check billing if the account is not completed

                  //TODO check if subscription_selected is not null

                  if (getters.canManageAccounts || getters.currentRole === 'owner') {
                    dispatch('getBilling')
                      .then(function (res) {
                      })
                      .catch(function (err) {
                        console.log('billing not active on account')
                        console.log(err)
                      })
                  }


                  //Instead of billing, we now check the payment methods if a subscription was selected
                  // dispatch('checkBilling')
                  //   .then(function(res){
                  //     let subscriptionType = res.data.subscription.database_name;
                  //     let status = res.data.status;  TODO do differently
                  //
                  //     if(status !== "active" && subscriptionType !== 'manual'){
                  //       console.log('billing status not active');
                  //     }else{
                  //       commit('setOnboardingStatus', true);
                  //     }
                  //
                  //     commit('setOnboardingStatus', true);
                  //   })


                })
                .catch(function (err) {
                  console.log('gt err');
                  console.log(err);
                  Vue.prototype.$swal.fire({
                    icon: 'error',
                    title: 'Couldn\'t retrieve onboarding status....',
                    text: err.response?.data.message || err,
                    customClass: {
                      confirmButton: 'btn btn-primary btn-fill'
                    }

                  })
                })
            })
            .catch(err => Vue.prototype.$swal.fire({
              icon: 'error',
              title: 'Getting accounts failed',
              text: err.response?.data.message || err,
              customClass: {
                confirmButton: 'btn btn-primary btn-fill'
              }

            }))

        })
        .catch(function (error) {
          //User was not authenticated correctly, loggedIn has been set to false so router should always redirect user to login page
        })
    },
    checkUserStatus({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('dashboard/api/v1/users')
          .then(function (response) {
            commit('setUserLoggedInStatus', true)
            commit('setUserObj', response.data.user)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
            commit('setUserLoggedInStatus', false)
          })
      })
    },
    async getAccounts({commit, state, dispatch}, useCache) {
      // // Use 'jwt' from localstorage as prefix:
      const jwt = localStorage.getItem('jwt').substring(0,10);
      const cacheDuration = 86400000 //Cache for 24 hours in milliseconds
      const now = new Date().getTime();
      const cache = await localForage.getItem(`${jwt}:cache:getAccounts`);
      const lastFetched = await localForage.getItem(`${jwt}:cache:getAccountsTime`);
      const cacheIsValid = (now - lastFetched) < cacheDuration;

      // // If it's already in localStorage then we don't need to fetch it again:
      // if (jwt && localStorage.getItem(`${jwt}:cache:getAccounts`)) {
      //   console.log('Loading cached accounts');

      //   return new Promise((resolve, reject) => {
      //     const data = JSON.parse(localStorage.getItem(`${jwt}:cache:getAccounts`));
      //     commit('setAccounts', data.accounts);
      //     resolve({ data: data });
      //   });
      // }

      return new Promise((resolve, reject) => {
        // If it should get the cached response do that, otherwise use the normal request logic
        // Only execute below code if useCache is provided
        // these values are used again later in the code with the same if check

        let useCachedResponse = useCache && cache && cacheIsValid

        if(useCachedResponse){
            //Accounts are cached, but now we want uncached data of just the restaurant the user is trying to load
            dispatch('getRestaurant')
              .then(function (restaurant) {
                // Succesfully retrieve the new restaurant
                // Insert the restaurant into the cached accounts response
                const newRestaurant = restaurant; // Assuming this is the new restaurant data
                let restaurantFound = false;

                // First, try to update the restaurant using currentAccountId to be quicker
                if (state.auth.accountId) {
                  for (let account of cache.accounts) {
                    if (account.id === state.auth.accountId) {
                      const restaurantIndex = account.restaurants.findIndex(r => r.id === newRestaurant.id);
                      if (restaurantIndex !== -1) {
                        restaurantFound = true;
                        account.restaurants[restaurantIndex] = newRestaurant;
                      }
                      break;
                    }
                  }
                }
                // Fallback: Loop through every account and every restaurant
                if (!restaurantFound) {
                  for (let account of cache.accounts) {
                    const restaurantIndex = account.restaurants.findIndex(r => r.id === newRestaurant.id);
                    if (restaurantIndex !== -1) {
                      account.restaurants[restaurantIndex] = newRestaurant;
                      break;
                    }
                  }
                }

                //Commit the cached accounts response
                commit('setAccounts', cache.accounts);

                const cachedResponse = {data: cache};
                resolve(cachedResponse);
              })
              .catch(function (err) {
                //Failed to get specific restaurant, to prevent issues just retry getAccounts but then uncached
                console.log('failed getting accounts cached')
                dispatch('getAccounts', false)
              })
        }

        //check again for useCachedResponse, if anything goes wrong with retrieving a single restaurant, just fall back to this method
        if(!useCachedResponse){
          axios.get(`dashboard/api/v1/accounts`)
            .then(function (response) {
              if(useCache){
                //First clear any old cache if present
                dispatch('clearAccountCache');

                // Set new response as cache using localForage
                // Localstorage can only handle 5MB, the non-gzipped accounts response can be 4.6MB on production
                // TODO in the future try gzipping for localForage to improve performance even more: https://multiaki.medium.com/save-network-traffic-by-saving-big-json-objets-on-the-browser-31edb35164c0
                try{
                  localForage.setItem(`${jwt}:cache:getAccounts`, response.data);
                  localForage.setItem(`${jwt}:cache:getAccountsTime`, now);
                }catch (err){
                  alert(err.response?.data.message || err)
                }

              }

              commit('setAccounts', response.data.accounts)
              resolve(response)

            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            })
        }
      })
    },
    updateAccount({commit, state, dispatch}, account) {
      let payload = Object.assign({}, account);
      if (payload.subscription) {
        payload.subscription = payload.subscription.database_name
      }
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}`, payload)
          .then(function (response) {
            commit('updateAccountInStore', account)
            resolve(response)

            //Invalidate accounts cache after having updated accounts
            dispatch('clearAccountCache');


          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getRestaurant({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}`)
          .then(function (response) {
            // Cannot commit restaurant to store right now it retrieve, because accounts call is not yet saved to the store
            // commit('updateRestaurantInStore', {restaurant: response.data, accountId: state.auth.accountId})
            resolve(response.data)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
  },
    updateRestaurant({commit, state}, restaurant) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${restaurant.id}`, restaurant)
          .then(function (response) {
            commit('updateRestaurantInStore', {restaurant: restaurant, accountId: state.auth.accountId})
            resolve(response)

          })
          .catch(function (err) {
            console.log(err);
            Vue.prototype.$swal.fire({
              icon: 'error',
              title: 'Couldn\'t update settings...',
              text: err.response?.data.message || err,
              customClass: {
                confirmButton: 'btn btn-primary btn-fill'
              }

            })
            reject(err);
          })
      })
    },
    clearAccountCache(){
      return new Promise((resolve, reject) => {
        //Set the cache key pattern of how the cache is saved
        const cacheKeyPatterns = [":cache:getAccounts", ":cache:getAccountsTime"];
        localForage.keys().then(function(keys) {
          // `keys` is an array of the keys in the storage
          keys.forEach(key => {
            if (cacheKeyPatterns.some(pattern => key.includes(pattern))) {
              localForage.removeItem(key);
            }
          });
          resolve()
        }).catch(function(err) {
          // This code runs if there were any errors
          console.log(err);
            console.log(err);
            reject(err);
        });
      })
    },
    setPosIntegration({commit, state}, posSettings) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/pos`, posSettings)
          .then(function (response) {
            if (response.data.authorization_url) {
              //POS is lightspeed, redirect the user to the authorization_url in a new subwindow
              window.location.href = response.data.authorization_url;


              //STUFF FOR POP UP WINDOW
              // let windowObjectReference = null;
              // let previousUrl = null;
              //
              // const openSignInWindow = (url, name) => {
              //   // remove any existing event listeners
              //   window.removeEventListener('message', receiveMessage);
              //   // window features
              //   const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
              //   if (windowObjectReference === null || windowObjectReference.closed) {
              //     /* if the pointer to the window object in memory does not exist
              //      or if such pointer exists but the window was closed */
              //     windowObjectReference = window.open(url, name, strWindowFeatures);
              //   } else if (previousUrl !== url) {
              //     /* if the resource to load is different,
              //      then we load it in the already opened secondary window and then
              //      we bring such window back on top/in front of its parent window. */
              //     windowObjectReference = window.open(url, name, strWindowFeatures);
              //     windowObjectReference.focus();
              //   } else {
              //     /* else the window reference must exist and the window
              //      is not closed; therefore, we can bring it back on top of any other
              //      window with the focus() method. There would be no need to re-create
              //      the window or to reload the referenced resource. */
              //     windowObjectReference.focus();
              //   }
              //
              //   // add the listener for receiving a message from the popup
              //   window.addEventListener('message', event => receiveMessage(event), false);
              //   // assign the previous URL
              //   previousUrl = url;
              //
              // };
              //
              // const receiveMessage = event => {
              //   // Do we trust the sender of this message? (might be
              //   // different from what we originally opened, for example).
              // };
              //
              // openSignInWindow(response.data.authorization_url, 'Sign in - Lightspeed');
              // resolve(response);

            } else {
              resolve(response)
            }


          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getMplusBranches({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/mplus-branches`)
          .then(function (response) {
            // commit('setMplusBranches', response.data) //TODO implement
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getMplusTerminals({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/mplus-terminals`)
          .then(function (response) {
            // commit('setMplusTerminals', response.data) //TODO implement
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getMplusPaymentMethods({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/mplus-payment-methods`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getTrivecPaymentMethods({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/trivec-payment-methods`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getPayments({commit, state}, pos) {
      let url = ''
      if (pos === 'vectron') url = 'vectron-payment-types';
      else if (pos === 'untill') url = 'untill-payment-methods';
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/${url}`)
          .then(function (response) {
            resolve(response.data)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    setPspIntegration({commit, state}, pspSettings) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/psp`, pspSettings)
          .then(function (response) {
            if (response.data.url) {
              window.location.href = response.data.url;
              resolve(response);

            } else {
              resolve(response)
            }


          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getMollieProfiles({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/mollie-profiles`)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getLightspeedKBusinesses({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/lightspeed-k-businesses`)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    createAccountBilling({state}, customerInfoObj) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/methods`, customerInfoObj)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateAccountBillingInfo({commit, state}, customerInfoObj) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/billing-info`, customerInfoObj)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    setAccountPaymentMethodActive({commit, state}, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/activate-payment-method/${payload.paymentMethodId}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deletePaymentMethod({commit, state}, paymentMethodId) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/methods/${paymentMethodId}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    setRestaurantPaymentMethodActive({commit, state}, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${payload.restaurantId}/activate-payment-method/${payload.paymentMethodId}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    createRestaurantBilling({state}, customerInfoObj) {
      return new Promise((resolve, reject) => {
        let restaurantId = customerInfoObj.restaurantId ?? state.auth.restaurantId;
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${restaurantId}/billing`, customerInfoObj)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateRestaurantBillingInfo({state}, customerInfoObj) {
      return new Promise((resolve, reject) => {
        let restaurantId = customerInfoObj.restaurantId ?? state.auth.restaurantId;
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${restaurantId}/billing-info`, customerInfoObj)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getBilling({commit, state}, forceReload = false) {
      return new Promise((resolve, reject) => {
        if (forceReload || (!state.billing.loaded && !state.billing.loading)) {
          state.billing.loading = true;
          axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/methods`)
            .then(function (response) {
              if (response.data['account'].payment_methods.length > 0) {
                commit('setAccountBillingStatus', true);
              } else {
                commit('setAccountBillingStatus', false);
              }
              commit('setBilling', response.data);
              commit('setAccountBilling', response.data['account']);
              commit('setRestaurantBilling', response.data['restaurants']);
              resolve(response);
            })
            .catch(function (error) {
              state.billing.loading = false;
              console.log(error);
              if (error.response.status === 400 || error.response.status === 500) {
                commit('setAccountBillingStatus', false)
              } else {
                alert(error);
              }
              reject(error);
            })
        } else {
          resolve(null);
        }
      })
    },
    getRestaurantBilling({commit, state}, restaurantId = null) {
      restaurantId = restaurantId ?? state.auth.restaurantId;
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${restaurantId}/billing`)
          .then(function (response) {
            resolve(response);
            commit('setRestaurantBilling', {
              restaurantId: restaurantId,
              billing: response.data
            })
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getUsersInAccount({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/users`)
          .then(function (response) {
            commit('setUsers', response.data.users)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAccountUserIdentityReports({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/users/identity/reports`)
          .then(function (response) {
            commit('setUserIdentityReports', response.data)
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAdminUsers({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get('dashboard/api/v1/admin/admin-users')
          .then(function (response) {
            commit('setAdminUsers', response.data.users)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    inviteUserToExistingAccount({commit, state}, user) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/users`, user)
          .then(function (response) {
            commit('addUserToStore', response.data.user); //TODO check if dit de juiste response teruggeeft
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    inviteUserToNewAccount({commit, state}, user) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/invite`, user)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateUser({commit, state}, user) {
      user.restaurant_id = state.auth.restaurantId;
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/users/${user.id}`, user)
          .then(function (response) {
            commit('updateUserInStore', user)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateUserInformation({commit, state}, user) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/users`, user)
          .then(function (response) {
            commit('setUserObj', user)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deleteUser({commit, state}, userId) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/users/${userId}`)
          .then(function (response) {
            commit('removeUserFromStore', userId)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    createAccount({commit, dispatch}, name) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/accounts', name)
          .then(function (response) {
            commit('addAccountToStore', response.data.account);
            resolve(response)

            //Invalidate accounts cache after having created a new account
            dispatch('clearAccountCache');

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getUnlinkedRestaurants({commit}) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.get('dashboard/api/v1/accounts/restaurants')
          .then(function (response) {
            commit('setUnlinkedRestaurants', response.data.restaurants)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    linkRestaurantToAccount({commit, dispatch}, dataObj) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        let restaurantsArr = [];
        restaurantsArr.push({id: dataObj.restaurant.id})
        axios.put(`dashboard/api/v1/accounts/${dataObj.accountId}/add-restaurants`, {"restaurants": restaurantsArr})
          .then(function (response) {
            //Change the store of the restaurant that has just been added to an account
            commit('removeFromUnlinkedRestaurants', dataObj.restaurant.id)
            commit('addRestaurantToAccount', {accountId: dataObj.accountId, restaurantToAdd: dataObj.restaurant})
            resolve(response)

            //Invalidate accounts cache after having updated accounts
            dispatch('clearAccountCache');

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAllSubscriptions({commit}) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.get('dashboard/api/v1/admin/subscriptions')
          .then(function (response) {
            commit('setSubscriptions', response.data.subscriptions)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    createSubscription({commit}, subscription) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/subscriptions', subscription)
          .then(function (response) {
            commit('setSubscriptions', [response.data.subscription])
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateSubscription({commit}, subscription) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/admin/subscriptions/${subscription.id}`, subscription)
          .then(function (response) {
            commit('updateSubscriptionInStore', subscription)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deleteSubscription({commit}, subscription) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/admin/subscriptions/${subscription.id}`)
          .then(function (response) {
            commit('deleteSubscriptionInStore', subscription)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getMethodPrices({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/admin/methods-prices`)
          .then(function(response) {
            commit('setMethodPrices', response.data.method_prices_groups)
            resolve(response)
          })
          .catch(function (err) {
            reject(err)
          })
      })
    },
    createMethodPrices({commit}, methodPriceGroup) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/methods-prices', methodPriceGroup)
          .then(function (response) {
            commit('setMethodPrices', [response.data])
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateMethodPrices({commit}, methodPriceGroup) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/admin/methods-prices/${methodPriceGroup.id}`, methodPriceGroup)
          .then(function (response) {
            commit('updateMethodPrices', methodPriceGroup)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deleteMethodPrices({commit}, methodPriceGroup) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/admin/methods-prices/${methodPriceGroup.id}`)
          .then(function (response) {
            commit('deleteMethodPrices', methodPriceGroup)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getPsps({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('dashboard/api/v1/admin/psp-credentials')
          .then(function (response) {
            commit('setPsps', response.data.psp_credentials)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    addPsp({commit}, psp) {
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/psp-credentials', psp)
          .then(function (response) {
            commit('addToPsps', response.data)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updatePsp({commit}, psp) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/admin/psp-credentials/${psp.id}`, psp)
          .then(function (response) {
            commit('updatePspsInStore', response.data)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getQrDocumentsConfig({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/qr-documents-config`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    generateQrDocuments({commit, state}, data) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/generate-qr-document`, data)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getRawQrDocument({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/show-qr-document`, {
            responseType: "arraybuffer",
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/pdf'
            }
          })
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    downloadQrDocuments({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/download-qr-documents`, {responseType: "blob"})
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAllDiscounts({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('dashboard/api/v1/admin/discounts')
            .then(function (response) {
              commit('setDiscounts', response.data.discounts)
              resolve(response)

            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            })
      })
    },
    createDiscount({commit}, discount) {
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/discounts', discount)
            .then(function (response) {
              commit('setDiscounts', [response.data])
              resolve(response)

            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            })
      })
    },
    updateDiscount({commit}, discount) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/admin/discounts/${discount.id}`, discount)
            .then(function (response) {
              commit('updateDiscountInStore', discount)
              resolve(response)

            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            })
      })
    },
    deleteDiscount({commit}, discount) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/admin/discounts/${discount.id}`)
            .then(function (response) {
              commit('deleteDiscountFromStore', discount)
              resolve(response)

            })
            .catch(function (error) {
              console.log(error);
              reject(error);
            })
      })
    },
    getResellers({commit, state}) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.get('dashboard/api/v1/admin/resellers')
          .then(function (response) {
            commit('setResellers', response.data.resellers)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    addReseller({commit, state}, email) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/resellers', {email_address: email})
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getOnboardingStatus({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/onboarding/status`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    completeUser({commit, state}, userDetails) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/onboarding/complete-user`, userDetails)
          .then(function (response) {
            commit('setOnboardingUserCompletedStatus', true)
            resolve(response)

          })
          .catch(function (error) {
            commit('setOnboardingUserCompletedStatus', false)
            console.log(error);
            reject(error);
          })
      })
    },
    completeAccount({commit, state}, accountDetails) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/onboarding/complete-account`, accountDetails)
          .then(function (response) {
            commit('setOnboardingAccountCompletedStatus', true)
            commit('setMandateUrl', response.data.url)
            accountDetails.id = state.auth.accountId;
            commit('updateAccountInStore', accountDetails)
            resolve(response)

          })
          .catch(function (error) {
            commit('setOnboardingAccountCompletedStatus', false)
            console.log(error);
            reject(error);
          })
      })
    },
    getSubscriptionSchedule({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/subscription-schedule`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    checkBilling({commit, state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/subscriptions`)
          .then(function (response) {
            commit('setSubscription', response.data.subscription.database_name);
            // commit('setSubscriptionStatus', response.data.status);
            // if(response.data.status === 'unverified' && response.data.subscription !== 'manual'){
            //     dispatch('updateBilling', {'subscription': response.data.subscription})
            // }
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateBilling({commit, state}, newSubscription) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/subscriptions`, {'subscription': newSubscription})
          .then(function (response) {
            // if (response.data.url) {
            //   commit('setMandateUrl', response.data.url)
            // }
            resolve(response)
            //TODO add this change to the store, to reflect when new subscription will go into effect

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    cancelBilling({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/subscriptions`)
          .then(function (response) {
            // if (response.data.url) {
            //   commit('setMandateUrl', response.data.url)
            // }
            resolve(response)
            //TODO add this change to the store, to reflect when new subscription will go into effect

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    createRestaurant({commit, state, dispatch}, restaurantData) {
      //Creates a restaurant in currently selected account and immediately selects that restaurant
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants`, restaurantData)
          .then(function (response) {
            commit('setRestaurant', response.data.restaurant.id)
            commit('addRestaurantToAccount', {
              accountId: state.auth.accountId,
              restaurantToAdd: response.data.restaurant
            })
            resolve(response)

            //Invalidate accounts cache after having created a new restaurant
            dispatch('clearAccountCache');


          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deleteRestaurant({commit, state}, restaurantId) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${restaurantId}`)
          .then(function (response) {
            commit('removeRestaurantFromAccount', {accountId: state.auth.accountId, restaurantToRemove: restaurantId})
            resolve(response)

            //Invalidate accounts cache after having deleted a restaurant
            dispatch('clearAccountCache');

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAllTablets({commit, state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/tablets/${state.auth.restaurantId}`)
          .then(function (response) {
            commit('setTabletsInfo', response.data)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    addTablet({commit, state}, username) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/tablets/${state.auth.restaurantId}`, {
          username: username,
          restaurant_id: state.auth.restaurantId
        })
          .then(function (response) {
            // commit('addTablet', response.data) TODO response geeft nog niet ID en object mee terug
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    relinkTablet({commit, state}, tabletId) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/tablets/${tabletId}/relink`, {})
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateTablet({commit, state}, data) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/tablets/${data['id']}`, data)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deleteTablet({commit, state}, tabletId) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/tablets/${tabletId}`, {})
          .then(function (response) {
            commit('deleteTablet', tabletId)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    importPosMenu({state}) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/import`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    importPosMenuByDepartment({state}, departmentId) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/import-by-department/${departmentId}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getUntillDepartments({state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/all-departments`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    clearPosItems({state}) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/pos-items`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getInventory({state}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/inventory/${state.auth.restaurantId}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updateInventory({state}, changes) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/inventory/${state.auth.restaurantId}`, changes)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getMenuItem({state}, menuItemId) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/${state.auth.restaurantId}/menu-item/${menuItemId}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getStatsOfMenuItem({state}, data) {
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/${state.auth.restaurantId}/menu-item/${data.menuItemId}/stats${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getHistoryOfMenuItem({state}, data) {
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      parameters += data.chart ? '&chart=1' : '';
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/${state.auth.restaurantId}/menu-item/${data.menuItemId}/history${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getCommentsOfMenuItem({state}, data) {
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/${state.auth.restaurantId}/menu-item/${data.menuItemId}/comments${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getOptionsChosenOfMenuItem({state}, data) {
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/${state.auth.restaurantId}/menu-item/${data.menuItemId}/options-chosen${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getStatsOfRestaurant({state}, data) {
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate + "&locations=" + data.locations;
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/revenue/${state.auth.restaurantId}/stats${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getRevenueOfRestaurant({state}, data) {
      let timeframe = state.analytics.timeframeMap.get(data.timeframeKey);
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      parameters += data.vat ? '&vat=' + data.vat : '';
      parameters += data.view ? '&view=' + data.view : '';
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/revenue/${state.auth.restaurantId}/${timeframe}${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getRevenueListOfRestaurant({state}, data) {
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      parameters += data.view ? '&view=' + data.view : '';
      parameters += data.tableFilter ? '&table_filter=' + data.tableFilter : '';

      if (data.locations) {
        parameters += '&locations=' + data.locations;
      }

      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/revenue/${state.auth.restaurantId}/list${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getPaymentsRevenue({state}, data) {
      let timeframe = state.analytics.timeframeMap.get(data.timeframeKey);
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      parameters += data.vat ? '&vat=' + data.vat : '';
      parameters += data.view ? '&view=' + data.view : '';
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/payments/${state.auth.restaurantId}/${timeframe}${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getOrdersOfRestaurant({state}, data) {
      let timeframe = state.analytics.timeframeMap.get(data.timeframeKey);
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;

      if (data.locations) {
        parameters += '&locations=' + data.locations;
      }
      
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/orders/${state.auth.restaurantId}/${timeframe}${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getReviews({state}, data){
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/reviews/${state.auth.restaurantId}${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getScans({state}, data){
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/scans/${state.auth.restaurantId}${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    downloadOrdersExport({state}, obj) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/export-orders/${state.auth.restaurantId}?startdate=${obj.dates.start}&enddate=${obj.dates.end}`, null, {
          params: obj.params,
          headers:
            {
              'Content-Disposition': "attachment; filename=template.xlsx",
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
          responseType: 'arraybuffer',
        })
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    downloadPaymentsExport({state}, obj) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/analytics/export-payments/${state.auth.restaurantId}?startdate=${obj.dates.start}&enddate=${obj.dates.end}`, null, {
          params: obj.params,
          headers:
            {
              'Content-Disposition': "attachment; filename=template.xlsx",
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
          responseType: 'arraybuffer',
        })
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getTotalGMV({state}, data) {
      let timeframe = state.analytics.timeframeMap.get(data.timeframeKey);
      let parameters = data.vat ? '?vat=' + data.vat : '';
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/admin/revenue/${timeframe}${parameters}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAllRestaurantRevenue({state, commit}, timerange) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/admin/restaurants-revenue?start_date=${timerange.start}&end_date=${timerange.end}`)
          .then(function (response) {
            commit('setRestaurantRevenues', response.data.results)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getPrevAllRestaurantRevenue({state, commit}, timerange) {
      //TODO MERGE WITH FUNCTION ABOVE AND DO CLEANER SOLUTION
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/admin/restaurants-revenue?start_date=${timerange.start}&end_date=${timerange.end}`)
          .then(function (response) {
            commit('setPrevRestaurantRevenues', response.data.results)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAllRestaurantPayments({state, commit}, timerange) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/admin/restaurants-revenue?start_date=${timerange.start}&end_date=${timerange.end}&payments=true`)
          .then(function (response) {
            commit('setRestaurantPayments', response.data.results)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getPrevAllRestaurantPayments({state, commit}, timerange) {
      //TODO MERGE WITH THE 3 FUNCTIONS ABOVE AND DO CLEANER SOLUTION
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/admin/restaurants-revenue?start_date=${timerange.start}&end_date=${timerange.end}&payments=true`)
          .then(function (response) {
            commit('setPrevRestaurantPayments', response.data.results)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAllRestaurantOrders({state, commit}, timerange) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/admin/restaurants-orders?start_date=${timerange.start}&end_date=${timerange.end}`)
          .then(function (response) {
            commit('setRestaurantOrders', response.data.results)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })

    },
    getOrderData({state}, params) {
      //Generate the paramsString if params were passed to this action (e.g. '?processed=false&payment_status=success')
      let paramsString = '';
      Object.keys(params).forEach(key => {
        paramsString += `${key}=${params[key]}&`
      })
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/orders/${state.auth.restaurantId}?${paramsString}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            reject(error);
          })
      })
    },
    getOrderPayoutStatus({state}, orderGroupId) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/orders/${state.auth.restaurantId}/orders/${orderGroupId}/payout-status`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getPosRequests({state}, orderGroupId) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/orders/${state.auth.restaurantId}/requests/${orderGroupId}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updateOrderData({state, commit}, order) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/orders/${state.auth.restaurantId}`, {'orders': [order]})
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    refundOrder({state, commit}, refund) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/orders/${state.auth.restaurantId}/orders/${refund['orderId']}/refund`, refund)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getPaymentsData({state}, params) {
      let paramsString = '';
      Object.keys(params).forEach(key => {
        paramsString += `${key}=${params[key]}&`
      })
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payments/${state.auth.restaurantId}?${paramsString}`)
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            reject(error);
          })
      })
    },
    getPaymentsPosRequests({state}, paymentId) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payments/${state.auth.restaurantId}/requests/${paymentId}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    refundPayment({state, commit}, refund) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/payments/${state.auth.restaurantId}/payments/${refund['paymentId']}/refund`, refund)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getSubscriptions({commit}) {
      //TODO DOES THIS WORK WITH ONLY COMMIT AND NO STATE AS FIRST ARUGMENT???
      return new Promise((resolve, reject) => {
        axios.get('dashboard/api/v1/subscriptions/tiers')
          .then(function (response) {
            commit('setSubscriptionTiers', response.data.subscriptions)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getCredits({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/credits`)
          .then(function (response) {
            commit('setCredits', response.data)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getQRs({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/tables/${state.auth.restaurantId}`)
          .then(function (response) {
            commit('setQRs', response.data.tables)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    createQR({state, commit}, QR) {
      return new Promise((resolve, reject) => {
        QR.restaurant_id = state.auth.restaurantId;
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/tables`, QR)
          .then(function (response) {
            commit('addQRToStore', QR)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateQR({state, commit}, QR) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/tables/${QR.id}`, QR)
          .then(function (response) {
            commit('updateQRInStore', QR)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deleteQR({state, commit}, QR) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/tables/${QR.id}`)
          .then(function (response) {
            commit('deleteQR', QR.id)
            resolve(response)
            Vue.prototype.$notify({
              title: `QR Code ${QR.code} deleted`,
            })

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getCoupons({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/coupons`)
          .then(function (response) {
            commit('setCoupons', response.data.coupons)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAccountCoupons({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/coupons`)
          .then(function (response) {
            commit('setAccountCoupons', response.data.coupons)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    searchCouponPos({state, commit}, query) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/coupons/search-pos`, {query: query})
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    createCoupon({state, commit}, coupon) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/coupons`, coupon)
          .then(function (response) {
            commit('createCoupon', response.data.coupon)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    createAccountCoupon({state, commit}, coupon) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/coupons`, coupon)
          .then(function (response) {
            commit('createAccountCoupon', response.data.coupon)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateCoupon({state, commit}, coupon) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/coupons/${coupon.id}`, coupon)
          .then(function (response) {
            commit('updateCoupon', coupon)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateAccountCoupon({state, commit}, coupon) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/coupons/${coupon.id}`, coupon)
          .then(function (response) {
            commit('updateAccountCoupon', coupon)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deleteCoupon({state, commit}, couponId) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/coupons/${couponId}`)
          .then(function (response) {
            commit('deleteCoupon', couponId)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deleteAccountCoupon({state, commit}, couponId) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/coupons/${couponId}`)
          .then(function (response) {
            commit('deleteAccountCoupon', couponId)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    exportCoupons({state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/export-coupons`,  {
          headers:
            {
              'Content-Disposition': "attachment; filename=template.xlsx",
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
          responseType: 'arraybuffer',
        })
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getAccountInvoices({state, commit, dispatch}) {
      return new Promise((resolve, reject) => {
        dispatch('getInvoices')
          .then(function (res) {
            let invoices = res.data.invoices;
            commit('setAccountInvoices', invoices)
            commit('setInvoicesCallComplete', true)
            resolve(invoices)
          })
          .catch(function (err) {
          })
      })
    },
    getInvoices({state, commit}, accountId=null) {
      let isAdmin = this.getters.isAdmin;
      if (! accountId) {
        accountId = state.auth.accountId
      }
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${accountId}/billing/invoices`)
          .then(function (response) {
            response.data.invoices = response.data.invoices.filter(invoice => {
              if (isAdmin) return true
              return invoice.status !== 'draft' && invoice.total > 0
            }).sort((a, b) => a.created_at < b.created_at ? 1 : -1);
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    searchInvoices({state, commit}, obj) {
      let isAdmin = this.getters.isAdmin;
      if (! obj.accountId) {
        obj.accountId = state.auth.accountId
      }

      const toQueryString = obj => {
        const keys = ['sales_bonus_person_id', 'sales_bonus_status', 'sales_bonus_month', 'sales_bonus_paid_month'];
        return keys
          .filter(key => obj[key])
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
          .join('&');
      }

      let searchQuery = obj.restaurantIds ? `restaurant_ids=${obj.restaurantIds.toString()}` : toQueryString(obj)

      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${obj.accountId}/billing/search-invoices?${searchQuery}`)
          .then(function (response) {
            response.data.invoices = response.data.invoices.filter(invoice => {
              if (isAdmin) return true
              return invoice.status !== 'draft' && invoice.total > 0
            }).sort((a, b) => a.created_at < b.created_at ? 1 : -1);
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    claimInvoiceBonus({state, commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/claim-invoice-bonus', payload)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    saveBonusDetails({state, commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/save-bonus-details', payload)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    fixInvoiceBonus({state, commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/fix-invoice-bonus', payload)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    fixInvoiceBonusDate({state, commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/fix-invoice-bonus-date', payload)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getReceiptUrl({state, commit}, chargeId) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/billing/receipt/${chargeId}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getApiTokens({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/api-tokens`)
          .then(function (response) {
            commit('setApiTokens', response.data.tokens)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    createApiToken({state, commit}, token) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/api-tokens`, token)
          .then(function (response) {
            commit('createToken', response.data.token)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    updateApiToken({state, commit}, token) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/api-tokens/${token.id}`, token)
          .then(function (response) {
            commit('updateToken', token)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    deleteApiToken({state, commit}, tokenId) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/api-tokens/${tokenId}`)
          .then(function (response) {
            commit('deleteToken', tokenId)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    searchHubspot({state}, query) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/search-hubspot`, {query: query})
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    searchHubspotDeals({state}, query) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/search-hubspot-deal`, {query: query})
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    searchMoneybird({state}, query) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/search-moneybird`, {query: query})
          .then(function (response) {
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    authenticateMailchimp({state}, query) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/mailchimp`)
          .then(function (response) {
            if (response.data.url) {
              window.location.href = response.data.url
            }
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            resolve(error)
          })
      })
    },
    getMailchimpLists({state}, query) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/mailchimp-lists`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getTempImage({state}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`temp/images/${params[0]}/${params[1]}`, {
          responseType: 'blob',
          timeout: 30000,
        })
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    postOAuth({state}, params) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/oauth/${params.service}`, params.code)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    generateQRCodes({state}, data) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/tables/qrs`, data, {
          responseType: 'blob'
        })
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    },
    authenticateUntappd({state}, query) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/untappd`)
          .then(function (response) {
            if (response.data.url) {
              window.location.href = response.data.url
            }
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            resolve(error)
          })
      })
    },
    sendTestOrders({state}, data) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/orders/${state.auth.restaurantId}/test`, data)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    createPayautAccount({state, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/account`, data)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    createStripeSubscription({state}, data) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/subscribe`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getUbosByRestaurantID({state, commit}, restaurantId) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${restaurantId}/ubos`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getUbos({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/ubos`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    createUbo({state, commit}, ubo) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/ubos`, ubo)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updateUbo({state, commit}, ubo) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/ubos/${ubo.id}`, ubo)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    createUboDocument({state, commit}, uboDocument) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/ubos/${uboDocument.ubo_id}/documents`, uboDocument)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updateUboDocument({state, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/ubos/${data.ubo_id}/documents/${data.uboDocument.id}`, data.uboDocument)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    createUboDocumentPage({state, commit}, uboDocumentPage) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/ubos/${uboDocumentPage.ubo_id}/documents/${uboDocumentPage.document_id}/pages`, uboDocumentPage)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    clearUboDocumentPage({state, commit}, uboDocumentPage) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/ubos/${uboDocumentPage.ubo_id}/documents/${uboDocumentPage.document_id}/pages/${uboDocumentPage.id}/clear`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    deleteUboDocument({state, commit}, uboDocument) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/ubos/${uboDocument.ubo_id}/documents/${uboDocument.id}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getUboKYCStatus({state, commit}, ubo) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/ubos/${ubo.id}/kyc-status`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getDocuments({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/documents`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    createDocument({state, commit}, document) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/documents`, document)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updateDocument({state, commit}, document) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/documents/${document.id}`, document)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    clearDocument({state, commit}, document) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/documents/${document.id}/clear`, document)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    deleteDocument({state, commit}, document) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/documents/${document.id}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updatePayoutInformation({state, commit}, payoutInformation) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/payout`, payoutInformation)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    submitKYC({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/submit-kyc`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getKYCStatus({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/kyc-status`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
        })
    },
    getPayoutStatus({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/payout-status`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
        })
    },
    getBankStatements({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/bank-statements`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    createBankStatement({state, commit}, statement) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/bank-statements`, statement)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updateBankStatement({state, commit}, statement) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/bank-statements/${statement.id}`, statement)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    clearBankStatement({state, commit}, statement) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/bank-statements/${statement.id}/clear`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    deleteBankStatement({state, commit}, statement) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/bank-statements/${statement.id}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    submitPayout({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/submit-payout`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getBalance({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/balance`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getAccountsBalances({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/accounts_holder_proxy`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getPaymentInvoices({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/invoices`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    downloadPaymentInvoice({state, commit}, invoice) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/invoices/${invoice.id}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getPayoutsProxied({ state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/payouts-proxy`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getPayouts({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/payouts`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    downloadSettlementReport({state, commit}, payout_id) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/payment-settings/${state.auth.restaurantId}/payouts/${payout_id}/download`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getPosImportStatus({state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/import-status`)
          .then(function (res) {
            resolve(res)
          })
          .catch(function (err) {
            console.log(err)
            reject(err)
          })
      })
    },
    getPosImportRequests({state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/import-requests`)
          .then(function (res) {
            resolve(res)
          })
          .catch(function (err) {
            console.log(err)
            reject(err)
          })
      })
    },
    getCustomDomainStatus({state}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/restaurants/${state.auth.restaurantId}/custom-domain/status`)
          .then(function (res) {
            resolve(res)
          })
          .catch(function (err) {
            console.log(err)
            reject(err)
          })
      })
    },
    getReceiptWithoutAccount(context, obj){
      return new Promise((resolve, reject) => {
        axios.get(`/api/v1/${obj.name}/orders/${obj.order}/receipt`, {responseType: 'blob'})
          .then(function (response) {
            const content = response.headers['content-type'];
            download(response.data, `Orderli receipt ${obj.order}`, content)
            resolve(response)

          })
          .catch(function (error) {
            console.log(error);
            reject(error)
          })
      })
    },
    getEndingTrials({commit, state}) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.get('dashboard/api/v1/admin/ending-trials')
          .then(function (response) {
            commit('setEndingTrials', response.data.ending_trials)
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    confirmEndingTrial({commit, state}, restaurantId) {
      //ADMIN ONLY
      return new Promise((resolve, reject) => {
        axios.post('dashboard/api/v1/admin/confirm-trial-end', {'restaurant_id': restaurantId})
          .then(function (response) {
            commit('removeEndingTrial', restaurantId)
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getActivityLog({state}, data) {
      let parameters = "?start=" + data.startDate + "&end=" + data.endDate;
      parameters += '&restaurant=' + state.auth.restaurantId;
      parameters += '&page=' + data.page;
      parameters += '&filter=' + data.filter;
      parameters += '&results=' + data.results;
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/admin/activity-log${parameters}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error);
            reject(error);
          })
      })
    },
    getUserPayoutStatus({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/tipping/payout-status`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getUserBalance({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/tipping/balance`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getUserBankStatements({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/tipping/bank-statements`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    createUserBankStatement({state, commit}, statement) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/tipping/bank-statements`, statement)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updateUserBankStatement({state, commit}, statement) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/tipping/bank-statements/${statement.id}`, statement)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    clearUserBankStatement({state, commit}, statement) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/tipping/bank-statements/${statement.id}/clear`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    deleteUserBankStatement({state, commit}, statement) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/tipping/bank-statements/${statement.id}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getUserDocuments({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/tipping/documents`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    createUserDocument({state, commit}, document) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/tipping/documents`, document)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updateUserDocument({state, commit}, document) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/tipping/documents/${document.id}`, document)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    clearUserDocument({state, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/tipping/documents/${data.document_id}/pages/${data.page_id}/clear`, data)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    deleteUserDocument({state, commit}, document) {
      return new Promise((resolve, reject) => {
        axios.delete(`dashboard/api/v1/tipping/documents/${document.id}`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    submitUserKYC({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/tipping/submit-kyc`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getReceivedTips({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/tipping/tips`)
          .then(function (response) {
            resolve(response)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getShifts({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/shifts/${state.auth.restaurantId}`)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    updateShifts({state, commit}, shifts) {
      return new Promise((resolve, reject) => {
        axios.put(`dashboard/api/v1/accounts/${state.auth.accountId}/shifts/${state.auth.restaurantId}`, shifts)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getPosTables({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/tables/${state.auth.restaurantId}/pos-tables`)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    getTips({state, commit}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`dashboard/api/v1/accounts/${state.auth.accountId}/tipping/${state.auth.restaurantId}/tips`, {
          params: params
        })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    },
    postTips({state, commit}, obj) {
      return new Promise((resolve, reject) => {
        axios.post(`dashboard/api/v1/accounts/${state.auth.accountId}/tipping/${state.auth.restaurantId}/tips`, obj)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      })
    }
  },
  mutations: {
    auth_request(state) {
      state.auth.status = 'loading'
    },
    auth_success(state, user) {
      state.auth.status = 'success'
      state.auth.loginDetails = user
    },
    auth_error(state) {
      state.auth.status = 'error'
    },
    setJwt(state, token) {
      localStorage.setItem('jwt', token);
      axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
      state.auth.token = token;
    },
    removeJwt(state) {
      localStorage.removeItem('jwt')
    },
    setAdminImpersonateRole(state, value) {
      state.auth.adminImpersonateRole = value;
    },
    setUserLoggedInStatus(state, value) {
      state.auth.loggedIn = value;

      if (value) document.cookie = "orderli_session=;domain=.orderli.com;Max-Age=604800";
      else {
        state.auth.status = '';
        document.cookie = 'orderli_session=; Max-Age=-99999999;';
      }
    },
    setUserObj(state, userObj) {
      Object.keys(userObj).forEach(function (key) {
        state.auth.user[key] = userObj[key];
      })
      Sentry.setUser({email: userObj.email});
      if(userObj.is_admin){
        //User is admin, show different UI
        //TODO TURN ON LATER WHEN GOOD DEMO MODE
        // window.Trengo = window.Trengo || {};
        // window.Trengo.render = false;
      }
    },
    setAccounts(state, accounts) {
      state.accounts = accounts;

      window.Trengo = window.Trengo || {};
      window.Trengo.contact_data = {
        email: state.auth.user.email,
        name: `${state.auth.user.first_name} ${state.auth.user.last_name}`,
        phone: state.auth.user.phone_number,
        custom_fields: [{
          field_id: 178573,
          value: accounts[0].name || null
        }, {
          field_id: 178558,
          value: accounts[0].subscription.database_name || null
        }, {
          field_id: 325188,
          value: accounts[0].restaurants.length ? `https://admin.orderli.com/orders?accountId=${accounts[0].id}&restaurantId=${this.currentRestaurantId || accounts[0].restaurants[0].id}` : 'https://admin.orderli.com/orders'
        }]
      };
    },

    setUsers(state, users) {
      state.admin.users = users;
    },
    setUserIdentityReports(state, reports) {
      state.admin.userIdentityReports = reports;
    },
    setAdminUsers(state, users) {
      state.admin.adminUsers = users;
    },
    addUserToStore(state, user) {
      state.admin.users.push(user);
    },
    updateAccountInStore(state, account) {
      let foundIndex = state.accounts.findIndex(x => x.id === account.id);
      Object.keys(account).forEach(function (prop) {
        state.accounts[foundIndex][prop] = account[prop];
      })
    },
    updateRestaurantInStore(state, data) {
      let account = state.accounts.find(x => x.id === data.accountId)
      let foundIndex = account.restaurants.findIndex(x => x.id === data.restaurant.id);

      let updatedRestaurant = { ...account.restaurants[foundIndex] };
      Object.keys(data.restaurant).forEach(function (prop) {
        updatedRestaurant[prop] = JSON.parse(JSON.stringify(data.restaurant[prop]));
      });

      Vue.set(account.restaurants, foundIndex, updatedRestaurant); //refresh Vue reactivity on restaurant change
    },
    updateUserInStore(state, user) {
      let foundIndex = state.admin.users.findIndex(x => x.id === user.id);
      Object.keys(user).forEach(function (prop) {
        state.admin.users[foundIndex][prop] = user[prop];
      })
    },
    removeUserFromStore(state, id) {
      let index = state.admin.users.findIndex(user => user.id === id);
      state.admin.users.splice(index, 1);
    },
    addAccountToStore(state, accountToAdd) {
      state.accounts.push(accountToAdd);
    },
    addRestaurantToAccount(state, data) {
      console.log(state.accounts.find(acc => acc.id === data.accountId));
      state.accounts.find(acc => acc.id === data.accountId)?.restaurants.push(data.restaurantToAdd);
    },
    removeRestaurantFromAccount(state, data) {
      let acc = state.accounts.find(acc => acc.id === data.accountId);
      acc.restaurants.splice(acc.restaurants.findIndex(restaurant => restaurant.id === data.restaurantToRemove), 1);
    },
    setUnlinkedRestaurants(state, restaurants) {
      state.admin.unlinkedRestaurants = restaurants;
    },
    removeFromUnlinkedRestaurants(state, restaurantId) {
      state.admin.unlinkedRestaurants = state.admin.unlinkedRestaurants.filter(restaurant => restaurant.id !== restaurantId);
    },
    setSubscriptions(state, subscriptionsArray) {
      state.admin.subscriptions = state.admin.subscriptions.concat(subscriptionsArray);
    },
    updateSubscriptionInStore(state, subscriptionChanges) {
      let subscriptionToChange = state.admin.subscriptions.find(x => x.id === subscriptionChanges.id)
      Object.keys(subscriptionChanges).forEach(function (prop) {
        subscriptionToChange[prop] = subscriptionChanges[prop];
      })
    },
    deleteSubscriptionInStore(state, subscriptionChanges) {
      let index = state.admin.subscriptions.findIndex(x => x.id === subscriptionChanges.id)
      state.admin.subscriptions.splice(index, 1)
    },
    setMethodPrices(state, methodPriceGroups) {
      state.admin.methodPriceGroups = state.admin.methodPriceGroups.concat(methodPriceGroups);
    },
    updateMethodPrices(state, methodPriceGroupChanges) {
      let methodPriceGroupToChange = state.admin.methodPriceGroups.find(x => x.id === methodPriceGroupChanges.id)
      Object.keys(methodPriceGroupChanges).forEach(function (prop) {
        methodPriceGroupToChange[prop] = methodPriceGroupChanges[prop];
      })
    },
    deleteMethodPrices(state, methodPriceGroupChanges) {
      let index = state.admin.methodPriceGroups.findIndex(x => x.id === methodPriceGroupChanges.id)
      state.admin.methodPriceGroups.splice(index, 1)
    },
    setPsps(state, pspArray) {
      state.admin.psps = state.admin.psps.concat(pspArray)
    },
    addToPsps(state, psp) {
      state.admin.psps.push(psp)
    },
    updatePspsInStore(state, pspChanges) {
      let pspToChange = state.admin.psps.find(x => x.id === pspChanges.id)
      Object.keys(pspChanges).forEach(function (prop) {
        pspToChange[prop] = pspToChange[prop];
      })
    },
    setDiscounts(state, discountsArray) {
      state.admin.discounts = state.admin.discounts.concat(discountsArray)
    },
    updateDiscountInStore(state, discountChanges) {
      let discountToChange = state.admin.discounts.find(x => x.id === discountChanges.id)
      Object.keys(discountChanges).forEach(function (prop) {
        discountToChange[prop] = discountChanges[prop];
      })
    },
    deleteDiscountFromStore(state, discount) {
      let index = state.admin.discounts.findIndex(x => x.id === discount.id)
      console.log(`Index: ${index}`)
      state.admin.discounts.splice(index, 1)
    },
    setOnboardingStatus(state, value) {
      state.auth.onboarding.completed = value;
    },
    setOnboardingUserCompletedStatus(state, value) {
      state.auth.onboarding.user_completed = value;
    },
    setOnboardingAccountCompletedStatus(state, value) {
      state.auth.onboarding.account_completed = value;
    },
    setMandateUrl(state, url) {
      state.auth.onboarding.mandateUrl = url;
    },
    setSubscription(state, subscriptionName) {
      state.auth.onboarding.subscription = subscriptionName;
    },
    setSubscriptionStatus(state, status) {
      state.auth.onboarding.subscriptionStatus = status;
    },
    setBilling(state, billingObj) {
      billingObj['loading'] = false;
      billingObj['loaded'] = true;
      state.billing = billingObj;
    },
    setAccountBilling(state, billingObj) {
      state.auth.onboarding.accountBilling = billingObj;
    },
    setAccountBillingStatus(state, status) {
      state.auth.onboarding.billingStatus = status;
    },
    setAccount(state, id) {
      localStorage.setItem('accountId', id)
      state.auth.accountId = id;
    },
    setRestaurant(state, id) {
      localStorage.setItem('restaurantId', id)
      state.auth.restaurantId = id;
      this.commit('setRestaurantCurrency');
    },
    setRestaurantBilling(state, data) {
      if (data.hasOwnProperty('restaurantId')) {
        state.restaurant_billing[data.restaurantId] = data.billing;
      } else {
        state.restaurant_billing = data;
      }
    },
    setRestaurantWithReload(state, id) {
      localStorage.setItem('restaurantId', id)
      state.auth.restaurantId = id;
      this.commit('setRestaurantCurrency', false);
      window.location.reload(); //Quick fix to reload and reset all state related settings, TODO do this better
    },
    setRestaurantCurrency(state, updateState=true) {
      let currency = this.getters.currentRestaurant?.currency || 'EUR';
      localStorage.setItem('restaurantCurrency', currency);
      if (updateState) {
        state.auth.restaurantCurrency = currency;
      }
    },
    setTabletsInfo(state, object) {
      state.devices.allTablets = object.tablets;
      state.devices.latestAppVersion = object.latest_version;
    },
    addTablet(state, device) {
      //TODO NOG NIET IN DE RESPONSE VAN DE API
      state.devices.allTablets.push(device);
    },
    removeTablet(state, deviceId) {
      state.devices.allTablets.splice(state.devices.allTablets.findIndex(tablet => tablet.id === deviceId), 1);
    },
    setResellers(state, array) {
      state.admin.resellers = array;
    },
    setSubscriptionTiers(state, subscriptionsArr) {
      state.subscriptions = subscriptionsArr;
    },
    setCredits(state, credits) {
      state.credits = credits;
    },
    setQRs(state, tablesArr) {
      state.qrs = tablesArr;
    },
    addQRToStore(state, QR) {
      state.qrs.push(QR);
    },
    updateQRInStore(state, QR) {
      let foundIndex = state.qrs.findIndex(x => x.id === QR.id);
      Object.keys(QR).forEach(function (prop) {
        state.qrs[foundIndex][prop] = QR[prop];
      })
    },
    deleteQR(state, id) {
      state.qrs.splice(state.qrs.findIndex(qr => qr.id === id), 1);
    },
    setAccountInvoices(state, invoices) {
      state.account.invoices = invoices;
    },
    setInvoicesCallComplete(state, value){
      state.account.invoicesCallComplete = value;
    },
    setCoupons(state, coupons) {
      state.restaurant.coupons = coupons;
    },
    setAccountCoupons(state, coupons) {
      state.account.coupons = coupons;
    },
    createCoupon(state, coupon) {
      state.restaurant.coupons.push(coupon);
    },
    createAccountCoupon(state, coupon) {
      state.account.coupons.push(coupon);
    },
    updateCoupon(state, updatedCoupon) {
      let couponCode = state.restaurant.coupons.find(coupon => coupon.id === updatedCoupon.id)
      couponCode = updatedCoupon;
    },
    updateAccountCoupon(state, updatedCoupon) {
      let couponCode = state.account.coupons.find(coupon => coupon.id === updatedCoupon.id)
      couponCode = updatedCoupon;
    },
    deleteCoupon(state, couponId) {
      state.account.coupons.splice(state.account.coupons.findIndex(coupon => coupon.id === couponId), 1);
    },
    deleteAccountCoupon(state, couponId) {
      state.account.coupons.splice(state.account.coupons.findIndex(coupon => coupon.id === couponId), 1);
    },
    setApiTokens(state, tokens) {
      state.restaurant.api_tokens = tokens;
    },
    createToken(state, token) {
      state.restaurant.api_tokens.push(token);
    },
    updateToken(state, updatedToken) {
      let couponCode = state.restaurant.api_tokens.find(coupon => coupon.id === updatedToken.id)
      couponCode = updatedToken;
    },
    deleteToken(state, tokenId) {
      state.restaurant.api_tokens.splice(state.restaurant.api_tokens.findIndex(token => token.id === tokenId), 1);
    },
    setRestaurantRevenues(state, data) {
      state.admin.restaurantRevenues = data;
    },
    setPrevRestaurantRevenues(state, data) {
      state.admin.prevRestaurantRevenues = data;
    },
    setRestaurantPayments(state, data) {
      state.admin.restaurantPayments = data;
    },
    setPrevRestaurantPayments(state, data) {
      state.admin.prevRestaurantPayments = data;
    },
    setRestaurantOrders(state, data) {
      state.admin.restaurantOrders = data;
    },
    setEndingTrials(state, data) {
      state.admin.endingTrials = data;
    },
    removeEndingTrial(state, restaurantId) {
      state.admin.endingTrials.splice(state.admin.endingTrials.findIndex(endingTrial => endingTrial.id === restaurantId), 1)
    }
  }
})
